var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("multi-select", {
    staticClass: "company-select",
    attrs: {
      value: _vm.selectedCompany,
      "track-by": "id",
      label: "name",
      placeholder: _vm.placeholder,
      options: _vm.companyOptions,
      searchable: true,
      "allow-empty": false,
      loading: _vm.loading,
      "deselect-label": "",
      "select-label": "",
    },
    on: {
      input: _vm.companySelected,
      "search-change": function ($event) {
        return _vm.search({ name: $event })
      },
    },
    scopedSlots: _vm._u([
      {
        key: "singleLabel",
        fn: function ({ option }) {
          return [_vm._v("\n    " + _vm._s(option.name) + "\n  ")]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }